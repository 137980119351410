$( document ).ready(function() {
    
    //$('#nav-icon').click(function(){
    //    $(this).toggleClass('open');
    //    $('.main').toggleClass('open');
    //    $('.sidebar-bg').toggleClass('open');
    //});

    
    $('.to_top_center a').click(function(){
        var speed = 1000; 
        var href= $(this).attr("href"); 
        var target = $(href == "#" || href == "" ? 'html' : href); 
        var position = target.offset().top-140; 
        $("html, body").animate({scrollTop:position}, speed, "swing"); 
        return false;
    });
    // _____________________________________________________________________
    
    
    var e=document.body.classList,lastScrollY = 300;
    window.addEventListener("scroll",function(){
        this.scrollY<lastScrollY?e.remove("hideUp"):e.add("hideUp");
        lastScrollY = this.scrollY;
        this.scrollY<200?e.remove("bg"):e.add("bg");
     
    })
    $('.sidenav').sidenav();
    $(".dropdown-trigger").dropdown();
    $('.show-more').click(function(){
        $(this).parent('.info').siblings('.more').slideToggle();
    })
      //});
      $('.top').click(function(){
        $('html,body').animate({ scrollTop: 0 }, 1000);   /* 返回到最頂上 */
        return false;
    });


    
    $('.collapsible').collapsible();
    
   

    //document.addEventListener('DOMContentLoaded', function() {
    //    var elems = document.querySelectorAll('.collapsible');
    //    var instances = M.Collapsible.init(elems, options);
    //});
    // Or with jQuery
    //$('.collapsible').collapsible();
    

    
});



